import axios from "axios";

/**
 * It's suggested to configure the RESTful endpoints in this file
 * so that there is only one source of truth, future update of endpoints
 * could be done from here without refactoring on multiple places throughout the app
 */
const API = {
    serverURL: "https://gtapi.goldentreasuresinc.com/api",
    imageURL: "https://gtapi.goldentreasuresinc.com/public/",
    // serverURL: "http://192.168.68.149:8000/api",
    // imageURL: "http://192.168.68.149:8000/",

    // serverURL: "http://127.0.0.1:8000/api",
    // imageURL: "http://127.0.0.1:8000/",

    // serverURL: "https://gtiapi.cisstaging.com/api/",
    // imageURL: "https://gtiapi.cisstaging.com/public/",

    auth: {
        login: '/login',
        signUp: '/signup',
    },
};

export default {API};
